.block-image {
    max-width: var(--width-main);
    margin: 0 auto;
    /*padding: var(--block-space-vertical-small) var(--block-space-horizontal);*/
    display: flex;
    flex-direction: column;

    padding-left: var(--block-space-horizontal);
    padding-right: var(--block-space-horizontal);
}

.block-image img {
    margin: 0 auto;
}

.block-image .image-ref-text {
    font-size: 12px;
    margin: 10px 0 auto 0;
}

/*.block-image picture {*/
/*    height: auto;*/
/*    display: flex;*/
/*    align-items: flex-end;*/
/*}*/

.block-image picture img {
    object-fit: contain;
}

/*.image-ref-text {*/
/*    display: block;*/
/*}*/

.block-image picture .fullsize {
    object-fit: cover;
    height: 800px;
    background-size: cover;
    background-position: center;
}

@supports (-webkit-line-clamp: 3) {
    .block-image .image-ref-text p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.block-image.module-sm {
    width: 100%;
}

/*This is needed here because the styles are loaded later than the block-images styles ...*/
.block-images .block-image.module-sm {
    width: unset;
}

/**
480x
800x
1024x
 */
.block-image .image-ref-container {
    width: 480px;
    margin: 0 auto;
}

@media (min-width: 800px) {  /* <- 600px */
    .block-image.module-sm {
        width: var(--width-text);
    }

    .block-image .image-ref-container {
        width: 800px;
        margin: 0 auto;
    }

    /*This is needed here because the styles are loaded later than the block-images styles ...*/
    .block-images .block-image.module-sm {
        width: unset;
    }
}
@media (min-width: 1024px) {

    .block-image .image-ref-container {
        width: 1024px;
        margin: 0 auto;
    }

}